import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import calendar from "../Assets/Images/arrow-3.png";
import global from "../Assets/Images/calendar.png";
import Data from "../Assets/Images/global.png";
import Dollar from "../Assets/Images/dollar-circle.png";
import Egypt_Flag from "../Assets/Images/Frame 1261155613.png";
import "../Assets/Css/Web.css";
import axios from "axios";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";
import Dropdown from "../Package/dropdown";
import Quantity from "../Package/Quantity";
import { useAuth } from "../Login/AuthProvider";
import Modal from "react-bootstrap/Modal";
import Card from "./Card";
import { yellow } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

function Package(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(props.show);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [item, setItem] = useState(props.item);
  const [country, setCountry] = useState(props.country);
  const [s, setS] = useState(props.uid);
  const [counter, setCounter] = useState(1);
  const [dataLink, setDataLink] = useState();
  // const [x, setX] = useState(props.show);
  console.log(props.item);
  const auth = useAuth();
  console.log(auth.token);

  const postPayment = async (p) => {
    console.log("pac", p); // we can access id now
    // console.log("response", response); // we can access id now
    // e.preventDefault();
    const response = await axiosConfig({
      method: "post",
      url: "https://tejwal-back.addictaco.com/api/get-payment-url",
      data: {
        quantity: counter,
        package_id: p.id,
        type: p.type,
        description: p.id,
        price: p.price,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
    })
      .then((res) => {
        console.log(res.data.data);
        window.location = `${res.data.data}`;
        // navigate("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You Should Login First",
        });
      });
  };

  return (
    <>
      {item.operators.map((y, index) => (
        <div className={s} key={index}>
          <div className="row">
            {y.packages.map((p, index) => (
              <div className="col-md-4" key={index} data-id={p.id}>
                <div className="">
                  <div className="global-package">
                    <div className="">
                      <h1>{p.title}</h1>
                    </div>
                    <div className="p-3">
                      <div className="data">
                        <h3>
                          <span>
                            <img src={Data} alt="Global" />
                            Coverage
                          </span>
                          <strong className="">{country.title}</strong>
                        </h3>
                      </div>
                      <div className="data">
                        <h3>
                          <span>
                            <img src={calendar} alt="arrows" />
                            Data
                          </span>
                          <strong>{p.data}</strong>
                        </h3>
                      </div>
                      <div className="data">
                        <h3>
                          <span>
                            <img src={global} alt="Calendar" />
                            Validity
                          </span>
                          <strong>{p.day} Days</strong>
                        </h3>
                      </div>
                      <div className="data">
                        <h3>
                          <span>
                            <img src={Dollar} alt="Calendar" />
                            Price
                          </span>
                          <strong>{p.price} $</strong>
                        </h3>
                      </div>
                      <Quantity counter={counter} />
                      <Dropdown />
                    </div>
                    <div className="text-center mt-5 mb-5">
                      <button
                        className="submit pt-3 pb-3"
                        onClick={() => postPayment(p)}
                      >
                        Buy Now
                        <i className="fa fa-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

export default Package;
