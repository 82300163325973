import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { axiosConfig } from "../Login/axiosAPI";
import ContactImage from "../Assets/Images/Image box.png"
import parse from "html-react-parser";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    msg: "",
  });

  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const contact = async (e) => {
    e.preventDefault();
    const response = await axiosConfig
      .post("https://tejwal-back.addictaco.com/api/form-submit", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {})
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };



  let [data, setData] = useState([]);
  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        `https://tejwal-back.addictaco.com/api/info`,
        {}
      );
      setData(response.data.data);
      console.log(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="utility">
        <div className="container">
          <div className="utility-card">
            <h1>Contact Us</h1>
            <p>
              Get in touch with us today and let us help you with any questions
              or inquiries you may have.
            </p>
          </div>
        </div>
      </div>
      <div className="contact">
        <div className="container">
          <div className="contact-form">
            <div className="row">
              <div className="col-md-6">
                <form>
                  <div>
                    <h2>Let's connect !</h2>
                    <p>
                      Let's align our constellations! Reach out and let the
                      magic of collaboration illuminate our skies.
                    </p>
                  </div>
                  <div className="form-group">
                    <label>Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>joylawson@gmail.com</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="joylawson@gmail.com"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="phone"
                      placeholder="(+1) 123 456 789"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      className="form-control"
                      name="msg"
                      rows={5}
                      placeholder="Message"
                      value={formData.msg}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div>
                    <button className="submit" type="submit" onClick={contact}>
                      Send<i className="fa fa-chevron-right"></i>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-6">
                <div className="contact-img">
                  <img src={ContactImage} alt="form-img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-details">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="details-card">
                <div className="details">
                  <i className="fa fa-phone"></i>
                  <div>
                    <h3>Call Us On:</h3>
                    <a href={`tel:${data?.phone}`}>{data?.phone}</a>
                  </div>
                </div>
                <div className="details">
                  <i className="fa fa-envelope"></i>
                  <div>
                    <h3>Email us:</h3>
                    <a href={`mailto:${data?.email}`}>{data?.email}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="details-card">
                <div className="details">
                  <i className="fa fa-location-dot"></i>
                  <div>
                    <h3>Main Office :</h3>
                    <a href="#!">{parse(`${data?.address}`)}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
