import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Assets/Css/Web.css";
import { useParams, useLocation, useNavigate } from "react-router-dom";

function SuccessOrder() {
  const { status,  message,  id } = useParams()
  // const location = useLocation();
  // const [post, setPost] = useState({});
  // useEffect(() => {
  //   setPost(location);
  // }, []);
  console.log(status);
  console.log(message);
  console.log(id);

  return (
    <>
      <h1>SuccessOrder</h1>
    </>
  );
}

export default SuccessOrder;
