import React from "react";
import { Link } from "react-router-dom";


function Touch() {
    return (
        <div className="container">
            <div className="touch">
                <h1>Get In Touch</h1>
                <p>Working on a story? We'd love to hear from you!</p>
                <Link to="/Contact" className="touch-button">Contact Us</Link>
            </div>
        </div>
    );
}

export default Touch;
