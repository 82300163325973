import React, { useState, useEffect } from "react";
import global from "../Assets/Images/calendar.png";

function Dropdown() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
        <div className="">
            <button
            onClick={() => setIsOpen(!isOpen)}
            className="dropdown-button"
            >
            {isOpen ? (
                <i className="fa fa-chevron-up"></i>
            ) : (
                <i className="fa fa-chevron-down"></i>
            )}
            Additional Information
            </button>
            {isOpen && (
            <>
                <div>
                <div className="data">
                    <h3>
                    <span>
                        <img src={global} alt="Calendar" />
                        Hotspot
                    </span>
                    <strong>Allowed</strong>
                    </h3>
                </div>
                </div>
                <div className="data">
                <h3>
                    <span>
                    <img src={global} alt="Calendar" />
                    Speed
                    </span>
                    <strong>3G/4G</strong>
                </h3>
                </div>
            </>
            )}
        </div>
    </>
  );
}

export default Dropdown;
