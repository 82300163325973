import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Eye from "../Assets/Images/Vision (1).png"
import Rocket from "../Assets/Images/rocket launch (1).png";
import Poster from "../Assets/Images/Rectangle 34624184.png";
import Wifi from "../Assets/Images/wifi.png";
import Dollar from "../Assets/Images/dollar-circle.png";
import cpu from "../Assets/Images/cpu.png";
import Main from "../Assets/Images/Group 1261152706.png";
import VideoPlayer from "../Assets/Video/11948319_1440_1920_30fps.mp4";
import { Video } from "reactjs-media";
import axios from "axios";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";
import parse from "html-react-parser";

function About() {

  let [data, setData] = useState([]);
  const getData = async () => {
      try {
          const response = await axiosConfig.get(
            `https://tejwal-back.addictaco.com/api/info`,
            {}
          );
          setData(response.data.data);
          console.log(response.data)
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
  };
  
  useEffect(() => {
      getData();
  }, []);
  return (
    <>
      <div className="utility">
        <div className="container">
          <div className="utility-card">
            <h1>About Us</h1>
            <p>
              We are dedicated to providing exceptional eSIM connectivity
              solutions to empower your global adventures
            </p>
          </div>
        </div>
      </div>
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <h2>About Us</h2>
                <p>{parse(`${data?.about}`)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mission">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="vision">
                <img src={Eye} alt="eye" />
                <h2>Our Vision</h2>
                <p>{parse(`${data?.vision}`)}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="vision">
                <img src={Rocket} alt="Rocket" />
                <h2>Our Mission</h2>
                <p>
                  {parse(`${data?.mission}`)}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="video-player">
                <Video src={VideoPlayer} controls={true} poster={Poster} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="choose">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <h2 className="main-heading">Why Choose تجوال !</h2>
              <p className="main-description">
                Enjoy reliable and affordable internet in your trips. We get you
                covered.
              </p>
            </div>
            <div className="col-md-6 text-center">
              <img src={Main} alt="Main" className="plant-img" />
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <ul>
                <li>
                  <img src={cpu} alt="cpu" />
                  <div>
                    <h3>Easy eSIM</h3>
                    <p>
                      Pick the eSIM option that suits your travel needs from
                      تجوال's offerings.
                    </p>
                  </div>
                </li>
                <li>
                  <img src={Dollar} alt="Dollar" />
                  <div>
                    <h3>Smart Pricing</h3>
                    <p>
                      Pick the eSIM option that suits your travel needs from
                      تجوال's offerings.
                    </p>
                  </div>
                </li>
                <li>
                  <img src={Wifi} alt="Wifi" />
                  <div>
                    <h3>Better Connectivity</h3>
                    <p>
                      Pick the eSIM option that suits your travel needs from
                      تجوال's offerings.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
