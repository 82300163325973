import React from "react";
import "../Assets/Css/Footer.css";
import Visa from "../Assets/Images/Visa.png";
import Master from "../Assets/Images/Mastercard.svg";
import GPay from "../Assets/Images/GooglePay.png";
import Amex from "../Assets/Images/Amex.png";
import PayPal from "../Assets/Images/PayPal.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-card">
          <div className="pay-img">
            <img src={Visa} alt="Visa" />
            <img src={Master} alt="Master" />
            <img src={GPay} alt="GPay" />
            <img src={Amex} alt="Amex" />
            <img src={PayPal} alt="PayPal" />
          </div>
          <p>©2024 تجوال All Rights are reserved️ </p>
          <Link to="/Terms">Terms & Conditions</Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
