import React, { useState, useEffect } from "react";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";

const FAQToggle = (x) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
      <div
        className={isActive ? "faq open" : "faq"}
        onClick={toggleClass}
        key={x.item.id}
      >
        <div className="faq-question">{x.item.question}</div>
        <div className="faq-answer">{x.item.answer}</div>
      </div>
    </>
  );
};

export default FAQToggle;
