import React, { useState, useEffect } from "react";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";
import FAQToggle from "./FAQToggle";

const FAQ = () => {
    // const [isActive, setActive] = useState(false);
  
    // const toggleClass = () => {
    //   setActive(!isActive);
    // };

  let [data, setData] = useState([]);
  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        `https://tejwal-back.addictaco.com/api/faqs`,
        {}
      );
      setData(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {data.map((x, index) => (
        <div className="col-md-6" key={index}>
          <FAQToggle item={x} />
        </div>
      ))}
    </>
  );
};

export default FAQ;
