import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Assets/Css/Web.css";
import axios from "axios";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";
import Package from "../Package/Package";
import RegPackage from "../Package/regPackage";

function Regional() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let [data, setData] = useState([]);
  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        `https://tejwal-back.addictaco.com/api/airalo/get-packages?type=global`
      );
      setData(response?.data?.data?.data);
      // console.log(response.data.data.data)
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  const [className, setClassName] = useState("country-package");
  const [className2, setClassName2] = useState("col-md-4");
  const [s, setS] = useState("d-none");
  const [button, setButton] = useState("text-center mt-5");

  function handleRemove(title) {
    console.log(title);
    const newList = data.filter((x) => x.title == title);
    setData(newList);
    setS("111");
    setClassName2("col-md-12");
    setButton("d-none ");
    console.log(title, newList);
  }
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="esims-country">
        <div className="row">
          {data.map((x, index) => (
            <div className={className2} key={index}>
              <a
                href="#!"
                onClick={() => {
                  handleRemove(x.title);
                  setIsOpen(true);
                }}
                className={className}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={x.image.url}
                    className="country-flag"
                    alt="country-flag"
                  />
                  <h3>{x.title}</h3>
                </div>
                <i className="fa fa-arrow-right"></i>
              </a>
              {isOpen ? (
                <div className={s}>
                  <RegPackage item={x} country={x} />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Regional;
