import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ContactImage from "../Assets/Images/Image box.png";

function Error() {
  return (
    <>
      <div className="error-card">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>The page you are looking for doesn't exist or has been moved.</p>
        <Link to="/Home" className="submit">
          Back To Home <i className="fa fa-chevron-right"></i>
        </Link>
      </div>
    </>
  );
}

export default Error;
