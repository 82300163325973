import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Assets/Css/Login.css";
import Touch from "../Pages/Get_In_Touch";
import Google from "../Assets/Images/Google.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

function ForgetPassword() {

  return (
    <>
      <div className="login">
        <form className="form" action="/OTP">
          <div className="row">
            <div className="col-md-12">
              <div className="forget">
                <Link to="/" className="back">
                  <i className="fa fa-chevron-left"></i>Back to login
                </Link>
                <h1>Forgot your password?</h1>
                <p>
                  Don’t worry, happens to all of us. Enter your email below to
                  recover your password
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="joylawson@gmail.com"
                  //   value={inputFields.email}
                  //   onChange={handleChange}
                ></input>
              </div>
            </div>
          </div>
          <button type="submit" className="submit">
            Submit <i className="fa fa-chevron-right"></i>
          </button>
        </form>
      </div>
    </>
  );
}

export default ForgetPassword;
