import './App.css';
import "./Components/Assets/Css/Web.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Login from "./Components/Login/Login";
import Touch from "./Components/Pages/Get_In_Touch";
import Home from './Components/Pages/Home';
import FAQ_page from './Components/Pages/FAQPage';
import About from './Components/Pages/About';
import Contact from './Components/Pages/Contact';
import Error from './Components/Pages/404';
import Terms from './Components/Pages/Terms';
import ForgetPassword from './Components/Login/ForgetPassword';
import OTP from './Components/Login/OTP';
import NewPassword from './Components/Login/SetPassword';
import Destination from './Components/Pages/Destination';
import Profile from './Components/Profile/Profile';
import AuthProvider from "./Components/Login/AuthProvider";
import PrivateRoute from "./Components/Login/route";
import ESIM_History from './Components/Profile/eSIM_History';
import SuccessOrder from './Components/Pages/successOrder';
import ErrorOrder from './Components/Pages/errorOrder';

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route element={<PrivateRoute />}>
              <Route path="/Login" element={<Login />} />
              <Route
                path="/order/success/:status/msg/:message/orderID/:id"
                element={<SuccessOrder />}
              />
              <Route path="/" element={<Home />} />
              <Route path="/FAQ_page" element={<FAQ_page />} />
              <Route path="/About" element={<About />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/404" element={<Error />} />
              <Route path="/Terms" element={<Terms />} />
              <Route path="/ForgetPassword" element={<ForgetPassword />} />
              <Route path="/NewPassword" element={<NewPassword />} />
              <Route path="/OTP/:email" element={<OTP />} />
              <Route path="/Destination/:id" element={<Destination />} />
              <Route path="/Profile" element={<Profile />} />
              <Route path="/ESIM_History" element={<ESIM_History />} />
            </Route>
          </Routes>
          <Touch />
          <Footer />
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
