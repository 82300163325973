import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Assets/Css/Login.css";
import Google from "../Assets/Images/Google.png"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router-dom";
import { axiosConfig } from "./axiosAPI";
import axios from "axios";
import Swal from "sweetalert2";

function Login() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("accessToken") || "");
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    password_confirmation: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const response = await axiosConfig
      .post("https://tejwal-back.addictaco.com/api/register", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        navigate(`/OTP/${formData.email}`);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const auth = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email !== "" && password !== "") {
      auth.loginAction({
          email: email,
          password: password
      });
      return;
    }
  };


  return (
    <>
      <div className="login">
        <Tabs>
          <TabList>
            <Tab>Sign In</Tab>
            <Tab>Sign up</Tab>
          </TabList>

          <TabPanel>
            <form className="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="joylawson@gmail.com"
                      onChange={(e) => setEmail(e.target.value)}
                      // value={formData.email}
                      // onChange={handleChange}
                    ></input>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="pass-wrapper">
                      <input
                        placeholder="Password"
                        name="password"
                        className="form-control"
                        type={passwordShown ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        // value={formData.password}
                        // onChange={handleChange}
                      />
                      <i
                        onClick={togglePasswordVisiblity}
                        className={
                          passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-between align-items-center mt-1 mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Remember Me
                    </label>
                  </div>
                  <Link to="/ForgetPassword" className="Forget">
                    Forget Password
                  </Link>
                </div>
              </div>{" "}
              <button type="submit" className="submit">
                Sign In <i className="fa fa-chevron-right"></i>
              </button>
              <div className="col-md-12 text-center">
                <p>Don’t have an account? Sign up</p>
              </div>
              <div className="col-md-12">
                <div className="or">
                  <div className="or-border"></div>
                  <span>Or</span>
                  <div className="or-border"></div>
                </div>
              </div>
              <div className="col-md-12">
                <a href="#!" className="login-with-google">
                  <img src={Google} alt="Google" />
                  <span>Login with Google</span>
                </a>
              </div>
            </form>
          </TabPanel>
          <TabPanel>
            <form className="form" onSubmit={handleRegister}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="First">User Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Abd allah"
                      value={formData.name}
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="joylawson@gmail.com"
                      value={formData.email}
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="email">Phone Number</label>
                    <input
                      type="number"
                      name="phone"
                      className="form-control"
                      placeholder="01154258990"
                      value={formData.phone}
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="pass-wrapper">
                      <input
                        placeholder="Password"
                        name="password"
                        className="form-control"
                        type={passwordShown ? "text" : "password"}
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <i
                        onClick={togglePasswordVisiblity}
                        className={
                          passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="Confirm_password">Confirm Password</label>
                    <div className="pass-wrapper">
                      <input
                        placeholder="Password"
                        name="password_confirmation"
                        className="form-control"
                        type={passwordShown ? "text" : "password"}
                        value={formData.password_confirmation}
                        onChange={handleChange}
                      />
                      <i
                        onClick={togglePasswordVisiblity}
                        className={
                          passwordShown ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>{" "}
                    </div>
                  </div>
                </div>
              </div>{" "}
              <button onClick={handleRegister} className="submit">
                Sign Up <i className="fa fa-chevron-right"></i>
              </button>
              <div className="col-md-12 text-center">
                <p>Already Have An Account? login</p>
              </div>
              <div className="col-md-12">
                <div className="or">
                  <div className="or-border"></div>
                  <span>Or</span>
                  <div className="or-border"></div>
                </div>
              </div>
              <div className="col-md-12">
                <a href="#!" className="login-with-google">
                  <img src={Google} alt="Google" />
                  <span>Login with Google</span>
                </a>
              </div>
            </form>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}

export default Login;
