import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Assets/Css/Login.css";
import Touch from "../Pages/Get_In_Touch";
import Google from "../Assets/Images/Google.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

function NewPassword() {
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };
  return (
    <>
      <div className="login">
        <form className="form" action="/">
          <div className="row">
            <div className="col-md-12">
              <div className="forget">
                <Link to="/" className="back">
                  <i className="fa fa-chevron-left"></i>Back to login
                </Link>
                <h1>Set Password</h1>
                <p>Enter Your New Password</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="pass-wrapper">
                  <input
                    placeholder="Password"
                    name="password"
                    className="form-control"
                    type={passwordShown ? "text" : "password"}
                    // value={inputFields.password}
                    // onChange={handleChange}
                  />
                  <i
                    // onClick={togglePasswordVisiblity}
                    className={passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}
                  ></i>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="Confirm_password">Confirm Password</label>
                <div className="pass-wrapper">
                  <input
                    placeholder="Password"
                    name="Confirm_password"
                    className="form-control"
                    type={passwordShown ? "text" : "password"}
                    // value={inputFields.password}
                    // onChange={handleChange}
                  />
                  <i
                    // onClick={togglePasswordVisiblity}
                    className={passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}
                  ></i>{" "}
                </div>
              </div>
              {/* {errors.password ? (
                <p className="error">
                  Password should be at least 5 characters long
                </p>
              ) : null} */}
            </div>
          </div>
          <button type="submit" className="submit">
            Submit <i className="fa fa-chevron-right"></i>
          </button>
        </form>
      </div>
    </>
  );
}

export default NewPassword;
