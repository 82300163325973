import React from "react";
import "../Assets/Css/Web.css";
import FAQ from "./FAQ";

function FAQ_page() {
  return (
    <>
      <div className="faqs">
        <div className="container">
          <div className="row">
            <FAQ />
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ_page;
