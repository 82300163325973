import React, { useState, useEffect, useRef } from "react";
import { Box, Modal, Slider, Button } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import Img from "../Assets/Images/99f960991f3a466f50725556a22c9edd.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { axiosConfig } from "../Login/axiosAPI";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "../Assets/Css/Login.css";
import calendar from "../Assets/Images/arrow-3.png";
import global from "../Assets/Images/calendar.png";
import Data from "../Assets/Images/global.png";
import Dollar from "../Assets/Images/dollar-circle.png";
import Dropdown from "../Package/dropdown";
import { useAuth } from "../Login/AuthProvider";

const ESIM_History = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const navigate = useNavigate();

  const CropperModal = ({ src, modalOpen, setModalOpen, setPreview }) => {
    const [slideValue, setSlideValue] = useState(10);
    const cropRef = useRef(null);

    //handle save
    const handleSave = async () => {
      if (cropRef) {
        const dataUrl = cropRef.current.getImage().toDataURL();
        const result = await fetch(dataUrl);
        const blob = await result.blob();
        setPreview(URL.createObjectURL(blob));
        setModalOpen(false);
      }
    };

    const boxStyle = {
      width: "300px",
      height: "300px",
      display: "flex",
      flexFlow: "column",
      justifyContent: "center",
      alignItems: "center",
    };
    const modalStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <Modal sx={modalStyle} open={modalOpen}>
        <Box sx={boxStyle}>
          <AvatarEditor
            ref={cropRef}
            image={src}
            style={{ width: "100%", height: "100%" }}
            border={50}
            borderRadius={150}
            color={[0, 0, 0, 0.72]}
            scale={slideValue / 10}
            rotate={0}
          />

          {/* MUI Slider */}
          <Slider
            min={10}
            max={50}
            sx={{
              margin: "0 auto",
              width: "80%",
              color: "cyan",
            }}
            size="medium"
            defaultValue={slideValue}
            value={slideValue}
            onChange={(e) => setSlideValue(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              border: "3px solid white",
              background: "black",
            }}
          >
            <Button
              size="small"
              sx={{ marginRight: "10px", color: "white", borderColor: "white" }}
              variant="outlined"
              onClick={(e) => setModalOpen(false)}
            >
              cancel
            </Button>
            <Button
              sx={{ background: "#5596e6" }}
              size="small"
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const [src, setSrc] = useState(null);

  // preview
  const [preview, setPreview] = useState(null);

  // modal state
  const [modalOpen, setModalOpen] = useState(false);

  // ref to control input element
  const inputRef = useRef(null);

  // handle Click
  const handleInputClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };
  // handle Change
  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
  };

  const LogOut = async (e) => {
    e.preventDefault();
    const response = await axiosConfig
      .post("https://tejwal-back.addictaco.com/api/logout", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
      })
      .then((res) => {
        navigate("/login");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const auth = useAuth();
  let [data, setData] = useState([]);
  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        `https://tejwal-back.addictaco.com/api/user/esims`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
        }
      );
      setData(response.data.data);
      console.log(response.data.data);
    } catch (error) {}
  };
console.log(auth.token);
  useEffect(() => {
    getData();
  }, []);


  let [user, setUser] = useState([]);
  const getUser = async () => {
    try {
      const response = await axiosConfig.get(
        `https://tejwal-back.addictaco.com/api/get-user-data`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
        }
      );
      setUser(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <div className="utility">
        <div className="container">
          <div className="utility-card">
            <h1>My Account</h1>
            <p>
              Get in touch with us today and let us help you with any questions
              or inquiries you may have.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="account">
              <div className="">
                <div className="">
                  <div className="myAccount">
                    {/* <main className="d-flex justify-content-center mb-5">
                      <CropperModal
                        modalOpen={modalOpen}
                        src={src}
                        setPreview={setPreview}
                        setModalOpen={setModalOpen}
                      />
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        ref={inputRef}
                        onChange={handleImgChange}
                      />
                      <div className="img-container">
                      <a href="/" className="add-button" onClick={handleInputClick}>
                        <i className="fa fa-camera add-icon"></i>
                      </a>
                        <img
                          src={
                            preview ||
                            `${Img}`
                          }
                          alt="preview"
                        />
                      </div>
                    </main> */}
                    <h2 className="text-center">
                      <i className="fa fa-user"></i>
                      {user?.name}
                    </h2>
                    <ul className="profile-links">
                      <li className="active">
                        <Link to="/ESIM_History" className="">
                          My eSIMs
                        </Link>
                      </li>
                      <li>
                        <Link to="/Profile" className="">
                          Account
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="" className="">Payment</Link>
                      </li>
                      <li>
                        <Link to="" className="">Refer And Earn</Link>
                      </li> */}
                      <li>
                        <Link to="" onClick={LogOut} className="">
                          Log Out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <h2>My eSIMs</h2>
              </div>
              {data.map((x, index) => (
                <div className="col-md-6" key={index}>
                  <div className="">
                    <div className="global-package">
                      <div className="">
                        <h1>{x?.esim_info?.simable?.package}</h1>
                      </div>
                      <div className="p-3">
                        {/* <div className="data">
                          <h3>
                            <span>
                              <img src={Data} alt="Global" />
                              Coverage
                            </span>
                            <strong className="">{x.title}</strong>
                          </h3>
                        </div> */}
                        <div className="data">
                          <h3>
                            <span>
                              <img src={calendar} alt="arrows" />
                              Data
                            </span>
                            <strong>{x?.esim_info?.simable?.data}</strong>
                          </h3>
                        </div>
                        <div className="data">
                          <h3>
                            <span>
                              <img src={global} alt="Calendar" />
                              Validity
                            </span>
                            <strong>
                              {x?.esim_info?.simable?.validity} Days
                            </strong>
                          </h3>
                        </div>
                        <div className="data">
                          <h3>
                            <span>
                              <img src={Dollar} alt="Calendar" />
                              Price
                            </span>
                            <strong>{x.price} $</strong>
                          </h3>
                        </div>
                        <div className="data">
                          <h3>
                            <span>
                              <img src={Dollar} alt="Calendar" />
                              Status
                            </span>
                            <strong>{x.esim_usage?.status} </strong>
                          </h3>
                        </div>
                        <Dropdown />
                        {x.esim_usage?.status == "ACTIVE" ? (
                          <Link
                            to={x.esim_info?.qrcode_url}
                            type="submit"
                            className="submit"
                          >
                            Install Now
                            <i className="fa fa-chevron-right"></i>
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ESIM_History;
